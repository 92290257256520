import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useItineraryService } from '@services/itinerary';

export const fetchBodyData = createAsyncThunk(
    'bodyData/fetchBodyData',
    async (itineraryIdentifier) => {
      const { getBodyData  } = useItineraryService();
      const response = await getBodyData(itineraryIdentifier);
      return response;
    }
  );

const bodyDataSlice = createSlice({
  name: "bodyData",
  initialState: {
    data: null,
  },
  reducers: {
    setBodyData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBodyData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { setBodyData } = bodyDataSlice.actions;
export default bodyDataSlice.reducer;
