import { useContext, useMemo } from "react";
import { useGlobalEnvConfig } from "@environment/GlobalEnvConfigProvider";

import { BasicData } from '@App';
import { safeImageUrl } from '@utils/Utils';

import './style.scss';

const CoBranding = ({ navbarHidden }) => {
    const { REACT_APP_RESOURCE_DOMAIN } = useGlobalEnvConfig();
    const { theme, branding } = useContext(BasicData);

    const coBrandingLogoUrl = useMemo(() => {
        return branding?.cobrand_logo_url ? safeImageUrl(REACT_APP_RESOURCE_DOMAIN, branding.cobrand_logo_url, 150, 150, "", "?fmt=png") : null;
    }, [branding])

    return (
        <div 
            className={`co-branding-logo ${theme?.logo_size ? theme.logo_size.toLowerCase() : 'small'}${navbarHidden ? ' hidden' : ''}`}>
            <img 
                src={coBrandingLogoUrl} 
                alt=""
                width="auto"
                height="auto"
            />
        </div>
    )
}

export default CoBranding;