export const liteItineraryBlocks = [
    { Type: "Introduction" },
    { Type: "FastFacts" },
    { Type: "InteractiveMap" },
    { Type: "DayByDaySummary" }, 
    { Type: "Pricing" },
    { Type: "TermsAndConditions" },  
]

export const enterpriseItineraryBlocks = [
    {
      Key: "0",
      DisplayName: "Introduction",
      Type: "Introduction",
      StackRankOrder: 0
    },
    {
      Key: "1",
      DisplayName: "Scheduled Departures",
      Type: "ScheduledDepartures",
      StackRankOrder: 1
    },
    {
      Key: "2",
      DisplayName: "Fast Facts",
      Type: "FastFacts",
      StackRankOrder: 2
    },
    {
      Key: "3",
      DisplayName: "Accommodation Summary",
      Type: "TripSummary",
      StackRankOrder: 3
    },
    {
      Key: "4",
      DisplayName: "Interactive Map",
      Type: "InteractiveMap",
      StackRankOrder: 4
    },
    {
      Key: "5",
      DisplayName: "Transport Summary",
      Type: "TransportSummary",
      StackRankOrder: 5
    },
    {
      Key: "6",
      DisplayName: "Pricing Summary",
      Type: "Pricing",
      StackRankOrder: 6
    },
    {
      Key: "7",
      DisplayName: "Documents",
      Type: "Documents",
      StackRankOrder: 7
    },
    {
      Key: "8",
      DisplayName: "Day by Day Summary",
      Type: "DayByDaySummary",
      StackRankOrder: 8
    },
    {
      Key: "9",
      DisplayName: "Enquiry",
      Type: "Enquiry",
      StackRankOrder: 9
    },
    {
      Key: "10",
      DisplayName: "Travel Guidance",
      Type: "TravelGuidance",
      StackRankOrder: 10
    },
    {
      Key: "11",
      DisplayName: "Terms And Conditions",
      Type: "TermsAndConditions",
      StackRankOrder: 11
    },
]