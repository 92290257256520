import React, { createContext, useState, useEffect, useContext } from "react";

const GlobalEnvConfigContext = createContext();

export const GlobalEnvConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (config == null) {
        try {
          const currentUrl = window.location.href;
          const basePath = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
          const response = await fetch(`${basePath}/env.json`); // Replace with your JSON file path
          const data = await response.json();
          setConfig(data);
          setIsConfigLoaded(true);
        } catch (error) {
          console.log("Could not load application configuration.");
          console.log(error);
        }
      }
    })();
  }, []);

  return isConfigLoaded && <GlobalEnvConfigContext.Provider value={config}>{children}</GlobalEnvConfigContext.Provider>;
};

export const useGlobalEnvConfig = () => {
  return useContext(GlobalEnvConfigContext);
};
