import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import envJSON from '@environment/env.json';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: envJSON.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY, // Replace this with your Application Insights instrumentation key
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: window.history }
    }
  }
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };