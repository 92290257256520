import { useContext, useMemo, useState } from 'react'
import { useGlobalEnvConfig } from "@environment/GlobalEnvConfigProvider";
import { Trans } from '@lingui/macro';

import { BasicData } from '@App';
import { safeImageUrl, checkBrandingType, getTextColorClass, getColor } from '@utils/Utils';
import avatar from '@/assets/images/avatar.svg';

import './style.scss';
import { useScrollPosition } from '@utils/hooks/useScrollPosition';

const Contact = () => {
    const { REACT_APP_RESOURCE_DOMAIN } = useGlobalEnvConfig();
    const { branding, type, theme, emergency_contact } = useContext(BasicData);
    const [showDetails, setShowDetails] = useState(false);
    const [top, setTop] = useState(true);

    const colors = getColor(theme);

    const imageSource = useMemo(() => {
        if (branding?.is_identity_branded) {
            if (branding?.logo_url_fragment) {
                return safeImageUrl(REACT_APP_RESOURCE_DOMAIN, `${branding.logo_url_fragment}`, 100, 100, "", "?fmt=png");
            }
            return avatar;
        } else {
            const brandingType = checkBrandingType(theme, type);
            if (brandingType === "consultant") {
                if (branding?.consultant_photo_url_fragment) {
                    return safeImageUrl(REACT_APP_RESOURCE_DOMAIN, `${branding.consultant_photo_url_fragment}`, 100, 100, "", "?fmt=png");
                } else {
                    return avatar;
                }
            } else {
                if (branding?.logo_url_fragment) {
                    return safeImageUrl(REACT_APP_RESOURCE_DOMAIN, `${branding.logo_url_fragment}`, 100, 100, "", "?fmt=png")
                } else {
                    return avatar;
                }
            }
        }
    }, [branding, type, theme])

    const name = useMemo(() => {
        if (branding?.is_identity_branded) {
            return branding.company_name;
        } else {
            const brandingType = checkBrandingType(theme, type);
            if (brandingType === "consultant") {
                return branding?.consultant_name;
            } else {
                return branding?.company_name;
            }
        }
    }, [branding, type, theme])

    const avatarComponent = useMemo(() => {
        return (
            <div className='avatar' onClick={() => setShowDetails(!showDetails)}>
                <img src={imageSource} alt="" />
            </div>
        )
    }, [showDetails, imageSource])

    const detailsComponent = useMemo(() => {
        return (
            <div className={showDetails ? 'avatar-details-wrapper show' : 'avatar-details-wrapper'}>
                <div className='close' onClick={() => setShowDetails(false)}><i className='icon-x'></i></div>
                <div className='avatar-details'>
                    <h6><Trans>Connect with</Trans></h6>
                    <h3>{name}</h3>
                    <div className='avatar-details-container'>
                        {branding?.phone && (
                            <div className='avatar-details-row'>
                                <i className={`icon-phone ${getTextColorClass(colors?.primary)}`} />
                                <p><a href={`tel:${branding.phone}`}>{branding.phone}</a></p>
                            </div>
                        )}

                        {branding?.email && (
                            <div className='avatar-details-row'>
                                <i className={`icon-mail ${getTextColorClass(colors?.primary)}`} />
                                <p><a href={`mailto:${branding.email}`}>{branding.email}</a></p>
                            </div>
                        )}

                        {branding?.website && (
                            <div className='avatar-details-row'>
                                <i className={`icon-globe ${getTextColorClass(colors?.primary)}`} />
                                <p><a target='blank' href={branding.website}>{branding.website}</a></p>
                            </div>
                        )}
                    </div>

                    {emergency_contact && (
                        <div className='avatar-details-row'>
                            <i className={`icon-info ${getTextColorClass(colors?.primary)}`} />
                            <p>{branding?.company_name ? `${branding?.company_name} - ` : ''}<Trans>Emergency Contact</Trans>: <br /> {emergency_contact}</p>
                        </div>
                    )}

                </div>
            </div>
        )
    }, [branding, showDetails, name, emergency_contact, colors?.primary])

    useScrollPosition(({currPos}) => {
       if (currPos.y > 0) {
        setTop(false)
       } else {
        setTop(true)
       }

    }, [top], null, true)

    return (
        <div className={`floating-contact${top ? ' is-top' : ''}`}>
            {avatarComponent}
            {detailsComponent}
        </div>
    )
}

export default Contact